import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"

import SupportInfo from "../components/support/support-info.js"
import SupportList from "../components/support/support-list.js"

const Support = () => {
    // Array of all the data for every Wet Ink event
    const ql_support = useStaticQuery(graphql`
        query {
            contentfulSupportInfo {
                title
                currentDonations
                donationTotal
                supportInfo {
                    json
                }
                board {
                    json
                }
                foundationSupporters {
                    json
                }
                individualDonors {
                    json
                }
            }
        }
    `)

    return (
        <Layout>
            <SEO title="Support" />
            <SupportInfo supportData={ql_support.contentfulSupportInfo} />
            <SupportList supportData={ql_support.contentfulSupportInfo} />
        </Layout>
    )
}

export default Support
