import React from "react"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { PayPalButton } from "react-paypal-button-v2"
import CircularSlider from "@fseehawer/react-circular-slider"
import { useMediaQuery } from "react-responsive"

import Style from "./support.module.scss"

// Creating a custom hook
function useInput(defaultValue) {
    const [value, setValue] = React.useState(defaultValue)
    function onChange(e) {
        setValue(e.target.value)
    }
    return {
        value,
        onChange,
    }
}

/**
 * SupportInfo (Default): Component for the content
 * of the support page
 */
const SupportInfo = props => {
    const isMobile = useMediaQuery({ query: "(max-width: 1024px)" })
    const donationValue = useInput()

    return (
        <div className={Style.supportInfoPane}>
            <h2>SUPPORT WET INK</h2>
            <hr />
            <div className={Style.donationSlider}>
                <CircularSlider
                    label="TOTAL DONATIONS"
                    labelColor="#000000"
                    knobColor="#0F0F00"
                    knobPosition={"bottom"}
                    knobDraggable={false}
                    hideKnob={true}
                    progressColorFrom="#D70D0D"
                    progressColorTo="#6FD70DA3"
                    progressSize={24}
                    trackColor="#eeeeee"
                    trackSize={24}
                    labelFontSize={isMobile ? 14 : 18}
                    valueFontSize={24}
                    prependToValue={"$"}
                    appendToValue={` /\$${props.supportData.donationTotal}`}
                    verticalOffset={10}
                    width={280}
                    min={0}
                    max={20000}
                    dataIndex={props.supportData.currentDonations}
                />
            </div>
            {documentToReactComponents(props.supportData.supportInfo.json)}
            <hr />
            <h2>Donate via Paypal</h2>
            <center>
                <b>Enter amount:</b>
                <br />
                <b>$</b>
                <input {...donationValue} placeholder="0" />
                <br />
                <br />
            </center>
            <div
                style={{
                    position: "relative",
                    justifyContent: "center",
                    display: "flex",
                    width: "50%",
                    left: "25%",
                }}
            >
                <PayPalButton
                    amount={donationValue.value}
                    options={{
                        clientId: `${process.env.GATSBY_PAYPAL_CLIENT_ID}`,
                    }}
                    onSuccess={(details, data) => {
                        alert("Payment processed!")
                    }}
                />
            </div>
            <h2>Or By Mail:</h2>
            <h3>
                <i>
                    Wet Ink Ensemble
                    <br />
                    49 Clarkson Ave, Apt 3A
                    <br />
                    Brooklyn, NY 11226
                    <br />
                </i>
            </h3>
        </div>
    )
}

export default SupportInfo
