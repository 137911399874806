import React from "react"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS } from "@contentful/rich-text-types"

import Style from "./support.module.scss"

/**
 * SupportList (Default): Component for the doners and board
 * on the support page
 */
const SupportList = ({ supportData }) => {
  return (
    <div className={Style.supportMiscPane}>
      <h2>BOARD & BOARD OFFICERS</h2>
      <hr />
      <div style={{ columnCount: 2, textIndent: "2vw" }}>
        {documentToReactComponents(supportData.board.json, board_options())}
      </div>
      <h2>FOUNDATION SUPPORTERS</h2>
      <hr />
      {documentToReactComponents(
        supportData.foundationSupporters.json,
        support_options()
      )}
      <h2>INDIVIDUAL DONORS</h2>
      <hr />
      <div style={{ columnCount: 2, textIndent: "1vw" }}>
        {documentToReactComponents(
          supportData.individualDonors.json,
          support_options()
        )}
      </div>
      <br />
    </div>
  )
}

export default SupportList

/* =================================================== */

/**
 * support_options: Default rich text parsing options
 * for the support page
 */
const support_options = () => {
  const option_node = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: node => {
        return (
          <img
            title={node.data.target.fields.title}
            style={{ paddingBottom: "1vw" }}
            src={`http:${node.data.target.fields.file["en-US"].url}`}
            alt={node.data.target.fields.title}
          />
        )
      },
      [BLOCKS.UL_LIST]: node => {
        return (
          <p style={{ margin: 0, padding: 0 }}>
            {node.content.map((listItem, key) => {
              return (
                <li style={{ margin: 0, padding: 0 }} key={key}>
                  {listItem.content[0].content[0].value}
                </li>
              )
            })}
          </p>
        )
      },
    },
  }
  return option_node
}

/**
 * board_options: Rich text parsing options
 * for the board members
 */
const board_options = () => {
  const option_node = {
    renderNode: {
      [BLOCKS.UL_LIST]: node => {
        return (
          <p style={{ margin: 0, padding: 0 }}>
            {node.content.map((listItem, key) => {
              return (
                <li style={{ margin: 0, padding: 0 }} key={key}>
                  <b>{listItem.content[0].content[0].value}</b><br />{listItem.content[0].content[1].value}
                </li>
              )
            })}
          </p>
        )
      },
    },
  }
  return option_node
}
